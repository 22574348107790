.selectMembershipBody {
  background-color: var(--ion-item-background);

  .topText {
    display: flex;
    align-items: center;
    flex-flow: column wrap;
    margin-bottom: 1rem;

    .headerText {
      display: flex;
      flex-flow: row;
      align-items: center;

      .titleClassName {
        color: var(--ion-color-primary);
        font-size: 3rem;
        font-weight: 800;
      }

      .vatSubheading {
        color: var(--ion-text-color);
        font-size: 1rem;
      }
    }
  }

  .subHeading {
    color: var(--ion-color-primary);
    font-size: 1.2rem;
  }

  .paymentButtonsContainer {
    display: flex;
    flex-flow: column wrap;

    .topButtonText {
      font-size: 1rem;
    }

    .firstButton {
      text-transform: none;
      font-size: 1.125rem;
      min-height: 3rem;
      font-weight: 800;
    }

    .secondButton {
      text-transform: none;
      font-size: 1.125rem;
      min-height: 3rem;
      font-weight: 800;
    }
  }
}

.tenderReadinessText {
  padding-top: 0;

  .tenderReadinessTextLine {
    line-height: 1.5rem;
  }

  ion-icon {
    color: var(--ion-color-success);
    margin-bottom: -0.13rem;
  }
}
