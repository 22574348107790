.checkMarkItem {
  display: flex;
  padding-block: 0.5rem;
  background-color: var(--ion-item-background);
}

.checkMarkIcon {
  font-size: 1.2rem;
  margin-top: 0.1rem;
}

.checkMarkItem:first-of-type {
  --padding-top: 0.5rem;
}

.checkMarkIconContainer {
  display: flex;
  height: 100%;
  margin-right: 0.2rem;
}

.descriptionText {
  margin: 0;
  font-size: 1rem;
  color: var(--ion-text-color);
}
