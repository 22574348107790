.map {
  height: 10rem;
  width: 100%;
}

.mapWithCircle {
  height: 10rem;
  width: 100%;

  &::after {
    $circle-size: 5rem;

    content: "";
    border: 0.3rem solid var(--ion-color-primary);
    border-radius: $circle-size;
    width: $circle-size;
    height: $circle-size;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
