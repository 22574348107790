.yearContainer {
  background-color: var(--ion-background-color);
  margin: 0 auto;
  text-align: center;
  padding: 0.5rem 0;
  margin-top: 1rem;
  width: calc(100% - 2rem);

  h5 {
    margin: 0;
    font-size: 0.9rem;
  }
}

.companyInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: var(--ion-item-background);
  padding-bottom: 1.5rem;
}

.profileWorkHistoryItem {
  --background: var(--ion-item-background);

  width: 100%;
}

.addressTextContainer {
  .addressText {
    font-size: 1.1rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.dotDivider {
  padding: 0 0.5rem;
}

.workHistoryItemSection {
  padding: 0;
}

.avatar {
  align-self: baseline;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--ion-color-light);
  height: 3rem;
  width: 3rem;

  ion-icon {
    margin: 0;
    font-size: 1.2rem;
  }
}

.architectAvatar {
  --border-radius: 0.01rem;

  align-self: baseline;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--ion-color-light);
  height: 3rem;
  width: 3rem;

  ion-icon {
    margin: 0;
    font-size: 1.2rem;
  }
}

.architectOfficeContainer {
  margin-top: 0.5rem;

  .registeredOfficeTitleAddress {
    color: var(--ion-color-medium);
    font-size: 0.9rem;
    margin-bottom: 0;
    white-space: break-spaces;
  }
}

.companyInfoLink {
  align-self: baseline;
  padding-top: -0.7rem;
}
