.popover {
  --width: 20rem;

  margin-top: 0.2rem;

  .popoverContent {
    background: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
  }

  &.mobile {
    margin-top: 0;

    --width: 18rem;

    margin-left: 5rem;

    &::part(arrow) {
      left: 1.3rem;
    }

    &::part(arrow)::after {
      background-color: var(--ion-color-primary);
    }
  }
}

.verifiedBadge {
  background: var(--ion-color-primary-contrast);
  width: 5rem;
  border: 0.063rem solid var(--ion-color-success);
  margin-top: 0.2rem;

  --padding-top: 0;

  color: var(--ion-text-color);

  ion-icon {
    margin-bottom: -0.13rem;
    margin-left: -0.2rem;
  }
}
