.label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  div {
    font-weight: bold;
    color: var(--ion-color-light);
  }
}

.projectAvatar {
  margin-right: 0.5rem;

  :hover {
    cursor: pointer;
  }
}

.clickableTextArea {
  width: 80%;

  :hover {
    cursor: pointer;
  }
}
