.modalContainer {
  padding: 0 1rem 2rem;

  &.editableInputs {
    ion-input {
      border: 0.05rem solid var(--ion-color-medium);
      border-radius: 0.4rem;

      /*
        Padding start is by default 0.5rem or 8px on android ion-input,
        however that is not the case for ios, this has a result an ugly ui on ios inputs.
        We're using that extra padding for consistency among the platforms
      */
      --padding-start: 0.5rem;
      --background: var(--ion-item-background);
    }
  }

  .messageTextArea {
    border: solid 1px var(--ion-color-medium);
    border-radius: 0.5rem;
    background-color: var(--ion-color-light);
  }
}

.titleContainer {
  display: flex;
}

.betaNotice {
  color: var(--ion-color-tertiary);
  font-weight: bold;
  margin-right: 1rem;
  background-color: rgba($color: var(--ion-color-tertiary-rgb), $alpha: 30%);
  padding: 0 0.2rem;
}
