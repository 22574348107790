@media only screen and (max-width: 768px) {
  .displayArchitectProfileContainer {
    --padding-start: 0;
    --padding-end: 0;
  }

  .deprecatedArchitectProfileContainer {
    --padding-start: 1rem;
  }
}
