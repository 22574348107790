.homeownerModalButtonContainer {
  border: solid 0.2rem var(--ion-color-tertiary);
  border-radius: 0.3rem;
  box-shadow: var(--theme-custom-box-shadow);
  margin-top: 0;
}

.homeownerModalButtonTitle {
  font-size: 1rem;
}

.homeownerModalCardHeader {
  padding-block: 0.5rem;
  padding-bottom: 0.2rem;
}

.homeownerModalCardContent {
  padding-block: 0.5rem;
}
