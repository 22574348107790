.paymentCard {
  ion-card-title {
    --color: var(--ion-color-dark);
  }

  ion-card-subtitle {
    --color: var(--ion-color-dark);

    font-weight: 400;
    font-size: 1rem;
  }

  ion-button {
    min-height: 3rem;
    font-size: 1rem;
    font-weight: 600;
  }

  .paymentFee {
    white-space: nowrap;
    font-size: 3rem;
    font-weight: 800;
    color: var(--ion-color-primary);
  }

  .cardContentText {
    font-size: 1.1rem;
    font-weight: 400;
    color: var(--ion-color-dark);
    padding-bottom: 0.5rem;
  }

  .cardContentTextNoBottomPaddingMargin {
    padding-bottom: 0;
    margin-bottom: 1rem;
  }

  .recommendedTag {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--ion-color-tertiary);
    color: var(--ion-color-light);
    padding: 0.3rem;
    margin-top: 0;
    font-weight: 600;
    border-radius: 0.2rem;
  }
}
