.costEstimatorSelector {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow: var(--theme-custom-box-shadow);
  margin: 1rem auto;
  background-color: var(--ion-item-background);
  padding: 1rem;
  border: solid 0.2rem var(--ion-color-tertiary);
  border-radius: 0.2rem;

  h4 {
    margin: 0;
  }

  p {
    margin: 0.5rem 0 1rem;
    font-size: 1rem;
  }
}
