.contractorOptionList {
  padding: 0;
}

.labelBrandText {
  margin: 0;
}

.menuItemHeader {
  font-family: var(--common-font-rebrand-text);
  color: var(--ion-text-color);
}
