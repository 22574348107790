.memberItem {
  p {
    font-size: 1rem;
  }

  // HACK: use of className is stripped when inside IonItem - better if we can find a more targeted selector solution
  > div {
    width: 50%;
    cursor: pointer;
  }

  .memberLabel {
    cursor: pointer;
  }

  ion-label:last-child {
    margin-top: -0.2rem;

    p {
      font-size: 0.75rem;
      color: var(--ion-color-medium);
    }
  }
}

.chatIconButton {
  border: 0;
  margin: 0;
}

.chatChipButton {
  --color: var(--ion-color-primary);

  border-color: var(--ion-color-primary);

  .chipText {
    font-size: 1rem;

    --color: var(--ion-color-primary);

    padding-bottom: 0.19rem;
  }
}

.popOverItem {
  cursor: pointer;
  background-color: var(--ion-item-background);
}

.popOverItem:hover {
  background-color: var(--ion-color-light-tint);
}

.menuIcon {
  cursor: pointer;
}

.messageUserButton {
  height: 70%;
  min-width: 28%;
}

.profileImg {
  width: 1.2rem;
  min-width: 1.2rem;
  margin-right: 0.3rem;
}

.chatboxIcon {
  width: 1.2rem;
  min-width: 1.2rem;
  margin-left: 0.1rem;
  margin-top: 0.2rem;
}

@media screen and (max-width: 576px) {
  .memberItem {
    // HACK: use of className is stripped when inside IonItem - better if we can find a more targeted selector solution
    > div {
      width: 70%;
    }
  }

  .messageUserButton {
    height: 70%;
    min-width: 18%;
  }

  .menuIcon {
    max-width: 20%;
  }

  .profileImg {
    min-width: 1.2rem;
    margin-right: 0;
    margin-bottom: 0;
  }
}

.rowButtonLabels {
  color: var(--ion-color-primary);
  margin: 0;
}
