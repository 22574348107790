.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .confirmButtonMargin {
    margin: 2rem 0.6rem 1rem;
  }
}

.showLeadCard {
  padding-bottom: 2rem;

  .item {
    > div {
      width: 50%;
    }
  }

  .editableContent {
    display: flex;
    justify-content: space-between;

    img {
      cursor: pointer;
    }
  }

  .tenderReadinessItems {
    > div {
      display: flex;
      align-items: center;

      ion-icon {
        margin-bottom: -0.2rem;
      }
    }
  }
}

.tenderPackAccordionContainerBorder {
  border: 0.1rem solid var(--ion-color-warning);
  box-shadow: none;
}

.tenderPackAlertIcon {
  margin-right: 0.8rem;
}

.tenderPackHeader {
  color: var(--ion-text-color);
}

.tenderPackAccordingBackgroundColor {
  /* stylelint-disable-next-line color-function-notation -- using rgb function for transparency */
  --background: rgba(var(--ion-color-warning-tint-rgb), 0.3);

  /* stylelint-disable-next-line color-function-notation -- using rgb function for transparency */
  background-color: rgba(var(--ion-color-warning-tint-rgb), 0.6);
}

.editProjectModal {
  .closeIcon {
    font-size: 1.5rem;
    color: var(--ion-color-text);
    cursor: pointer;
    margin-right: 1rem;
  }
}

.weaverContractorPreviewTextBold {
  font-weight: bold;
}

.descriptionItem {
  color: var(--ion-color-dark);
  font-size: 1rem;
  padding-left: 1rem;
}

@media screen and (min-width: 768px) {
  .paymentPickerModal {
    --height: 98vh;
    --width: 55vw;
  }
}
