.ionList {
  margin-top: 1em;

  .inviteTeamMemberText {
    color: var(--ion-color-medium);

    .inviteTeamMemberSubtitle {
      margin-bottom: 0.5em;
    }
  }
}

.individualConnectionItem {
  margin-top: 0.5em;

  --inner-padding-start: 0.5em;
  --inner-padding-top: 0.5em;
  --background: var(--ion-background-color);
  --border-radius: 0.3em;
  --inner-padding-bottom: 0.7em;

  ion-label {
    margin-left: 0.5em;
  }
}

// Deprecated from MW-2443 and reliant on FF 'MW-2443-QoL1-ui-updates'

.deprecatedIndividualConnectionItem {
  margin-top: 0.5em;

  --inner-padding-start: 0.5em;
  --inner-padding-top: 0.5em;
  --background: var(--ion-item-background);
  --border-radius: 0.3em;
  --inner-padding-bottom: 0.7em;

  ion-label {
    margin-left: 0.5em;
  }
}
