.acceptRejectButtons {
  ion-button {
    margin: 1rem;

    ion-spinner {
      transform: scale(50%);
    }
  }
}

.descriptionCopy {
  padding: 0.3rem 0;
  font-size: 1rem;
}

.tenderReadinessText {
  padding-top: 0;

  ion-icon {
    margin-bottom: -0.13rem;
  }
}

.tenderReadinessTitle {
  --min-height: 1rem;

  margin-bottom: 0.5rem;
}

.map {
  height: 10rem;
  width: 100%;

  &::after {
    $circle-size: 7rem;

    content: "";
    border: 0.3rem solid var(--ion-color-dark-tint);
    border-radius: $circle-size;
    width: $circle-size;
    height: $circle-size;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.card {
  ion-item {
    --border-style: none;
  }
}

.titleLabel {
  font-size: 1rem;
}

.row {
  width: 50%;
}

// remove me when you remove the flag
.actionButtons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 1rem 0;

  ion-button {
    width: 8rem;

    ion-spinner {
      transform: scale(50%);
    }
  }
}

.projectFull {
  background-color: var(--ion-color-danger);
  color: var(--ion-color-dark-contrast);
  height: 2rem;
  padding-top: 0.4rem;
  font-size: 1rem;
  font-weight: 700;
}

.blurred {
  filter: blur(0.25rem);
}

.outlineButton {
  margin-top: 0.5rem;

  --border-width: 0.08rem;
}

.fomoContractors {
  ion-icon {
    padding-left: 0;
  }
}

.tenderReadinessContainer {
  margin-bottom: 1rem;
}

.descriptionLabel {
  color: var(--ion-color-dark);
  padding-left: 1rem;
  font-size: 1rem;
}

.countDownTextColor {
  margin-left: 0.2rem;
  color: var(--ion-color-danger);
}

.docPreviewImage {
  width: 25rem;
  height: 20vh;
  cursor: pointer;
  border-radius: 0.25rem;
  overflow: hidden;
}

.stickyButtonLeadAcceptReject {
  background-color: var(--ion-item-background);
}

.outerContainer {
  display: grid;
  place-items: center;
  margin-bottom: 1rem;
  max-height: 15rem;
  padding-bottom: 0.5rem;
}

.documentPreviewPadding {
  padding-inline: 1rem;

  > .previewTitle {
    font-size: 1.05rem;
    color: var(--ion-text-color);
    margin-bottom: 1rem;
  }
}

.tenderPackAccordionContainerBorder {
  border: 0.1rem solid var(--ion-color-warning);
  box-shadow: none;
}

.tenderPackAlertIcon {
  margin-right: 0.8rem;
}

.tenderPackHeader {
  color: var(--ion-text-color);
}

.tenderPackAccordingBackgroundColor {
  /* stylelint-disable-next-line color-function-notation -- using rgb function for transparency */
  --background: rgba(var(--ion-color-warning-tint-rgb), 0.3);

  /* stylelint-disable-next-line color-function-notation -- using rgb function for transparency */
  background-color: rgba(var(--ion-color-warning-tint-rgb), 0.6);
}

.tenderPackFirstParagraphNoMarginTop {
  margin-top: 0;
}

@media screen and (max-width: 768px) {
  .docPreviewImage {
    width: 50vw;
  }
}

@media screen and (max-width: 566px) {
  .docPreviewImage {
    width: 65vw;
  }
}
