.title {
  font-weight: 500;
  font-size: 1.2rem;
}

.ionOutlineButton {
  --border-width: 0.08rem;
}

.ionText {
  line-height: 1.7rem;
}

.confirmBudgetButton {
  margin-top: 1rem;
}

.descriptionEditModalButton {
  margin-top: 1rem;
}

.finishesItemHeader {
  margin-bottom: 0;
  align-self: baseline;
  font-family: var(--common-font-rebrand-text);
  font-weight: 600;
  font-size: 1.125rem;
}

.finishesItemDescription {
  margin-top: 0.2rem;
}

.descriptionTextArea {
  min-height: 10rem;
  background-color: var(--ion-item-background);
  border: 0.1rem solid var(--ion-color-light);
  border-radius: 0.5rem;
  padding-inline: 0.25rem;
}

.ionFooter {
  background: var(--ion-background-color);
}
