.lightGrey {
  color: var(--ion-color-medium);
}

.buttonIcon {
  margin-right: 0.5rem;
}

.editIcon {
  margin-left: 0.2em;

  // solves the issue of tiny edit icon when companyTradingAs is too long
  font-size: min(2.2em);
}

.ionItem {
  --ion-item-background: none;
}

.cursorEnabled {
  cursor: pointer;
}

.ionContent {
  --ion-background-color: var(--ion-color-light-tint);
}

@media only screen and (max-width: 768px) {
  .deprecatedArchitectProfileContainer {
    --padding-start: 1rem;
  }
}
