.companyInfoContainer {
  background-color: var(--ion-color-medium-contrast);
  padding: 0 1rem;
  padding-bottom: 1rem;
  border-radius: 0 0 0.3rem 0.3rem;

  p {
    color: var(--ion-color-light-grey);
    line-height: 1.5;
    margin: 0;
  }

  svg {
    margin-top: 0.5em;
    margin-right: 1em;
  }

  ion-icon {
    color: var(--theme-light-blue);
    height: 0.8em;
  }

  .companyNumber {
    margin-top: 0.5rem;
  }

  .companyTradingName {
    margin-bottom: 0;
    max-width: 70%;
  }

  .architectTeamType {
    margin-bottom: 0.25rem;
  }

  .registeredOfficeTitle {
    font-size: 0.9rem;
    margin: 0;
  }

  .registeredOfficeTitleAddress {
    font-size: 0.8rem;
  }

  .mapSection {
    padding: 0;
    margin-top: 0.5rem;
  }
}

.soleTraderItem {
  background-color: var(--ion-background-color);

  --background: var(--ion-background-color);
}
