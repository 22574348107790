.label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  div {
    font-weight: bold;
    color: var(--ion-color-light);
  }
}

.memberAvatar {
  cursor: pointer;
  margin-right: 0.5rem;
}
