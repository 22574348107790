.container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0.25rem;
  margin: 0;
  height: 100%;
  width: 100%;
}

.swiper {
  height: 100%;
  max-width: 95vw;
}

.slideContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 1rem;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 1rem;
}

.contentContainer {
  width: 100%;

  ion-row {
    margin-bottom: 0.5em;
  }
}

.itemContainer {
  border-radius: 1rem;
}

.item {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.8rem;
}

.label {
  font-size: 1rem;
}

.profileImageContainer {
  display: flex;
  align-items: center;
  max-width: 15rem;
}

.subtext {
  font-size: 0.8em;
  margin: 0.5em 0;
}

.profileImage {
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 0.5rem;
  color: var(--ion-color-medium);
}

.errorMessage {
  color: var(--ion-color-danger);
  font-size: 0.8rem;
  margin-bottom: 1.4rem;
}

.soleTraderSubtitleTradingAs {
  font-size: 0.8rem;
  margin-top: -1.2rem;
}

.ionItemSoleTraderBackground {
  --background: var(--ion-background-color);
}

.soleTraderInputContainer {
  width: 100%;

  .editCompanyInput {
    --background: white;
    --padding-start: 0.5rem;

    margin-top: 0.4rem;
    border: solid var(--ion-color-medium) 1px;
    border-radius: 0.4rem;
  }
}
