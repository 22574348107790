.cardContainer {
  display: flex;
  justify-content: flex-end;

  .card {
    margin-inline: 0;
    width: 25rem;
    cursor: pointer;

    ion-item-divider {
      min-height: 0;
    }
  }
}

.weaverStatsContainer {
  padding: 0;

  .statRowContainer {
    display: flex;

    .statIcon {
      font-size: 1.2rem;
      margin-right: 0.25rem;
      margin-bottom: -0.13rem;
    }
  }

  .tieredBadgeRangeContainer {
    background-color: var(--ion-item-background);

    .badgeTitle {
      margin: 0;
    }
  }
}

.statRowContainer:last-child {
  margin-bottom: 0;
  padding-bottom: 1rem;
}

.avatarInfo {
  display: inline-flex;
}

.avatarDetailItem {
  p:not(:first-of-type) {
    margin-top: -0.5em;
    text-transform: lowercase;
  }

  p:not(:first-of-type)::first-letter {
    text-transform: uppercase;
  }

  .teamType {
    color: var(--ion-color-medium-tint);
  }

  ion-avatar {
    margin-right: 0.5rem;
  }
}

.divider {
  border-width: 0.08rem;
}

.cardFooter {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
